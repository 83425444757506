import classnames from 'classnames';
import * as React from 'react';
import ProductListItem from '../../common/ProductListItem/ProductListItem';
import { Product } from '../../models/search';
import { wsmVisibleFieldsBuilder } from '../../utils/wsmVisibleFieldsBuilder';
import ListControls from '../ListControls';
import PaginationWithRangeInfo from '../PaginationWithRangeInfo';
import { DEFAULT_SORT_OPTIONS } from '../ProductList/ProductList';
import ProductListWrapper from '../ProductListWrapper';
import { ProductListWrapperProps } from '../ProductListWrapper/models';
import styles from './styles/wsmProductListWrapper.module.scss';
// eslint-disable-next-line css-modules/no-unused-class
import searchPageStyles from '../WsmSearchPage/searchPage.module.scss';

const WsmProductListWrapper = ({
  itemsPerPage = 5,
  currentPage = 1,
  onDataReceived,
  ...props
}: Omit<ProductListWrapperProps, 'visibleFields' | 'renderList'>) => {
  const [page, setPage] = React.useState(currentPage);
  const [sort, setSort] = React.useState(props.selectedSort);
  const [itemsPerpageIntenal, setItemsPerpage] = React.useState(itemsPerPage);
  const [totalItems, setTotalItems] = React.useState(0);
  const [orientation, setOrientation] = React.useState(props.orientation);
  const [hide, setHide] = React.useState(false);
  const [hasExactMatch, setHasExactMatch] = React.useState(false);
  const [hasReceivedData, setHasReceivedData] = React.useState(false);

  const queryString = new URLSearchParams(window.location.search);
  const searchTerm = queryString.get('q');

  React.useEffect(() => {
    setOrientation(props.orientation);
  }, [props.orientation]);

  React.useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  React.useEffect(() => {
    setSort(props.selectedSort);
  }, [props.selectedSort]);

  React.useEffect(() => {
    setItemsPerpage(itemsPerPage);
  }, [itemsPerPage]);

  const renderPagnation = () => {
    if (!props.showPagination || !totalItems || !page || !itemsPerpageIntenal) {
      return null;
    }
    return (
      <PaginationWithRangeInfo
        totalItems={totalItems}
        currentPage={page}
        onChangePage={(newPage) => {
          props.onPageChanged?.(newPage);
          setPage(newPage);
        }}
        itemsPerPage={itemsPerpageIntenal}
      />
    );
  };

  const willRenderExactPartMatchMsg = () => {
    // this means exact part match is not enabled on customer
    if (typeof hasExactMatch === 'undefined') return false;

    return !hasExactMatch && hasReceivedData && totalItems && searchTerm;
  };

  const renderNoExactPartMatch = () => {
    if (props.renderNoExactPartMatchMessage) {
      return props.renderNoExactPartMatchMessage;
    }
    return (
      <h4
        className={classnames(
          searchPageStyles.noResultMsg,
          'Pl-WsmProductListWrapper--no-results-msg'
        )}
      >
        No exact match is found for <b>{searchTerm}</b>
        <br />
        Here are some suggestions:
      </h4>
    );
  };

  return (
    <>
      {props.showControls ? (
        <ListControls
          styled={props.styled}
          orientation={orientation}
          onLayoutChange={(value) => {
            setHide(true);
            setOrientation(value);
            props.onLayoutChange?.(value);
            setTimeout(() => {
              setHide(false);
            }, 100);
          }}
          onItemsPerPageChange={(value) => {
            setItemsPerpage(value);
            props.onItemsPerPageChange?.(value);
          }}
          onSortChange={(value) => {
            setSort(value);
            props.onSortChange?.(value);
          }}
          itemsPerPage={itemsPerpageIntenal}
          sortOptions={DEFAULT_SORT_OPTIONS}
          selectedSort={sort}
        />
      ) : null}

      {renderPagnation()}
      {willRenderExactPartMatchMsg() ? renderNoExactPartMatch() : null}
      <ProductListWrapper
        {...props}
        className={classnames({ [styles.hide]: hide }, props.className)}
        onLayoutChange={setOrientation}
        onDataReceived={(data) => {
          onDataReceived?.(data);
          setTotalItems(data?.total || 0);
          setHasReceivedData(true);
          if (data.exactMatchInResult) {
            setHasExactMatch(true);
          }
        }}
        showControls={false}
        selectedSort={sort}
        itemsPerPage={itemsPerpageIntenal}
        currentPage={page}
        showPagination={false}
        orientation={orientation}
        renderList={(data) => (
          <>
            {data.map((product) => {
              const {
                product: mutatedProduct,
                visibleFields,
              } = wsmVisibleFieldsBuilder(product as Product);
              return (
                <ProductListItem
                  key={product.id}
                  className={classnames({
                    [styles.resultItemStyledInGrid]:
                      props.styled && props.orientation === 'vertical',
                  })}
                  data={mutatedProduct}
                  styled={props.styled}
                  orientation={orientation}
                  visibleFields={visibleFields}
                  noImageUrl={props.noImageUrl}
                  linkEl={props.linkEl}
                  onItemClick={props.onItemClick}
                />
              );
            })}
          </>
        )}
      />
      {renderPagnation()}
    </>
  );
};

export default WsmProductListWrapper;
